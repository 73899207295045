import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Designing Time",
  "date": "2014-12-11T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <figure>
  <iframe src="https://www.youtube.com/embed/h5hpIEKoeBc" allowFullScreen></iframe>
  <figcaption>Ustwo, explaining their process for designing watch faces for Android Wear.</figcaption>
    </figure>
    <p><a parentName="p" {...{
        "href": "http://ustwo.com"
      }}>{`Ustwo`}</a>{`, makers of the beautiful `}<a parentName="p" {...{
        "href": "https://search.itunes.apple.com/WebObjects/MZContentLink.woa/wa/link?mt=8&path=apps%2fmonumentvalley"
      }}>{`Monument Valley`}</a>{` recently partnered with Google to `}<a parentName="p" {...{
        "href": "http://wear.ustwo.com"
      }}>{`design a few watch faces`}</a>{` for `}<a parentName="p" {...{
        "href": "http://www.android.com/wear/"
      }}>{`Android Wear`}</a>{`. They did an excellent job at not only displaying time in a way that is visually interesting, but also reinventing how a watch should tell time. For example, one of the watch face only shows you the time left until your next appointment, allowing you to focus on `}<em parentName="p">{`what`}</em>{` you have to do, instead of `}<em parentName="p">{`when`}</em>{`.`}</p>
    <p>{`As we’ve come to expect, this is top notch work. I recommend watching the video to see how they approached the problem.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      